import React from 'react';

import NavigationBar from 'components/interfaces/NavigationBar';
import { ScrollablePanel } from 'components/ui/Panel';

function Body(props: any) {
  return (
    <main className={`layout-body flex-grow  h-full ${props.className || ''}`}>
      <ScrollablePanel className="p-16 pb-4 pt-20 flex flex-col h-full">
        {props.children}
      </ScrollablePanel>
    </main>
  );
}

function Layout(props: any) {
  return (
    <div id={'layout'} className={`layout h-full ${props.className || ''}`}>
      {props.children}
    </div>
  );
}

Layout.Body = Body;
Layout.NavigationBar = NavigationBar;

export default Layout;
