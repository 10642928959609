import React, { PropsWithChildren } from 'react';

export type PanelProps = {
  children?: React.ReactNode;
  className?: string;
  ref?: React.RefObject<HTMLDivElement>;
};

const Content = ({ children, className }: PropsWithChildren<PanelProps>) => {
  const classes = ['px-2', 'py-4'];

  if (className) {
    classes.push(className);
  }
  return <div className={classes.join(' ')}>{children}</div>;
};

export const Panel = React.forwardRef<HTMLDivElement, PanelProps>((props, ref) => {
  const { children, className } = props;
  return (
    <div
      ref={ref}
      className={`panel pt-4 flex flex-col flex-grow max-w-full max-h-full bg-primary-grey4 ${
        className || ''
      }`}
    >
      {children}
    </div>
  );
});

type ScrollablePanelProps = PanelProps & {
  onScroll?: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
};

export function ScrollablePanel({ children, className, onScroll }: ScrollablePanelProps) {
  return (
    <div
      onScroll={onScroll}
      className={`scrollbar-thin scrollbar-thumb-system-grey5 scrollbar-track-system-grey6 scrollbar-thumb-rounded-full scrollbar-track-rounded-full overflow-y-scroll ${className}`}
    >
      {children}
    </div>
  );
}
