import React from 'react';

import BaseButton, {
  ButtonAsButton,
  ButtonAsLink,
  getVariant,
} from 'components/ui/Button/Button.utils';

type ButtonProps = ButtonAsButton | ButtonAsLink;

const getType = (type: string) => {
  switch (type) {
    case 'secondary': {
      return `text-system-grey7 bg-system-grey2 enabled:hover:bg-system-grey2 enabled:hover:text-system-grey6 enabled:active:bg-system-grey2 enabled:active:text-system-grey5 disabled:bg-system-grey5 disabled:text-system-grey4`;
    }
    case 'tertiary': {
      return `text-system-grey3 bg-system-grey6 enabled:hover:bg-system-grey5 enabled:hover:text-system-grey2 enabled:active:bg-system-grey6 enabled:active:text-system-grey3 disabled:bg-system-grey7 disabled:text-system-grey5`;
    }
    case 'destructive': {
      return `text-primary-white bg-primary-red enabled:hover:bg-dark-red1 enabled:hover:text-primary-white enabled:active:bg-dark-red2 enabled:active:text-light-red1 disabled:bg-dark-red2 disabled:text-light-red1`;
    }
    case 'cancel': {
      return `text-system-grey2 bg-system-grey6 enabled:hover:bg-system-grey5 enabled:hover:text-system-grey2 enabled:active:bg-primary-black enabled:active:text-system-grey2 disabled:bg-primary-black disabled:text-system-grey5`;
    }
    default: {
      // primary
      return `text-primary-white bg-primary-blue enabled:hover:bg-light-blue1 enabled:hover:text-primary-white enabled:active:bg-primary-blue  enabled:active:text-dark-blue1 disabled:bg-dark-blue2 disabled:text-primary-blue active:ring-0`;
    }
  }
};

export const Button = ({
  type = 'primary',
  variant = 'action',
  loading = false,
  disabled = false,
  ...props
}: ButtonProps) => {
  const { className, icon, autofocus, ...rest } = props;
  // Double negation is needed to prevent Type 'unknown' is not assignable to boolean type error
  const autoFocus = !!autofocus;

  const iconBase = `inline ${variant === 'large' ? 'h-5 w-5' : 'h-3.33 w-3.33 '} mr-2.33`;
  const base = `inline-flex items-center font-inter justify-center rounded-default min-w-fit whitespace-nowrap min-w-fit transition-colors`;

  const classes = `${base} ${className} ${getVariant(variant)} ${getType(type)}`;
  const Icon = icon;

  return (
    <BaseButton autofocus={autoFocus} className={classes} disabled={disabled} {...rest}>
      <span className={`flex items-center ${loading ? 'invisible' : 'block'}`}>
        {Icon && <Icon className={iconBase} />}
        {props.children}
      </span>
      {loading ? (
        <span
          style={{ borderTopColor: 'transparent' }}
          className={
            'absolute text-primary-white border-solid rounded-full animate-spin w-4 h-4 border-2'
          }
        />
      ) : (
        ''
      )}
    </BaseButton>
  );
};
