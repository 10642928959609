import { useRouter } from 'next/router';
import posthog from 'posthog-js';
import React, { PropsWithChildren, useEffect } from 'react';

import { supabase } from 'lib/supabase';

export default function PostHogWrapper({ children }: PropsWithChildren<any>) {
  const router = useRouter();

  useEffect(() => {
    // Identify only once
    supabase.auth.getSession().then(({ data, error }) => {
      if (error) {
        console.error(error);
      }
      if (data?.session?.user) {
        posthog?.identify(data.session.user.id, {}, { email: data.session.user.email });
        posthog?.people?.set({ email: data.session.user.email });
      }
    });

    // Track page views
    const handleRouteChange = () => {
      posthog.capture('$pageview');
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);
  return <>{children}</>;
}
