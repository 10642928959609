import { ReactNode } from 'react';
import { FiAlertCircle } from 'react-icons/fi';

import { Text } from '../Typography';

type ErrorProps = {
  message?: string;
  children?: ReactNode;
};

export default function Error({ message, children }: ErrorProps) {
  return (
    <div className="w-full h-full flex-grow flex items-center justify-center bg-system-grey7 rounded-lg flex-col ">
      <div className="space-y-3 text-primary-red flex flex-col items-center">
        <FiAlertCircle size={24} />
        <Text variant="body" className="text-center">
          {message || "Oh no! Something went wrong. Hang tight, we're on it"}
        </Text>
      </div>
      {children || null}
    </div>
  );
}
