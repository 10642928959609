import { useRouter } from 'next/router';
import { FiGithub } from 'react-icons/fi';

import useSignInWithGithub from 'lib/hooks/account/useSignInWithGithub';

import CenteredInfoLayout from 'components/layouts/CenteredInfoLayout';
import { Button } from 'components/ui/Button';

function Auth() {
  const router = useRouter();
  const { asPath } = router;
  const { refetch, isFetching } = useSignInWithGithub(asPath as string, {
    enabled: false,
  });

  return (
    <CenteredInfoLayout
      showLogo
      title="Login to Unweave"
      description="Login with your Github account"
    >
      <Button
        variant={'large'}
        icon={FiGithub}
        autofocus={true}
        loading={isFetching}
        onClick={() => refetch()}
      >
        Login with Github
      </Button>
    </CenteredInfoLayout>
  );
}

export default Auth;
