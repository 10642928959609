import { Menu, Transition } from '@headlessui/react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { Fragment, useState } from 'react';
import { FiFile, FiLayout, FiLogOut, FiUser } from 'react-icons/fi';

import useUser from 'lib/contexts/UserProvider';
import useSignOut from 'lib/hooks/account/useSignOut';

import { Text } from '../Typography';

const Account: React.FC = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const user = useUser();
  const router = useRouter();
  const { owner } = router.query as { owner: string };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const { refetch } = useSignOut({
    enabled: false,
    onSuccess: () => {
      router.push(`/${owner}`);
    },
  });

  return (
    <Menu className="relative" as={'div'}>
      <Menu.Button className="h-10 w-10 rounded-full bg-primary-blue flex items-center justify-center overflow-hidden">
        <Image src={user.avatar_url} width={40} height={40} alt="avatar" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-1 z-100 mt-2 -mr-1 min-w-fit w-44 bg-system-grey6 origin-top-left rounded-xl shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-h-64 overflow-y-scroll scrollbar-thin">
          <Link
            href={`/${owner}`}
            className="text-system-grey3 hover:text-system-grey1 transition-colors  block px-4 text-left py-2 text-sm whitespace-nowrap hover:bg-system-grey6 pt-3"
          >
            <Text variant="body" className="flex items-center">
              <FiLayout size={20} className="mr-4" />
              Dashboard
            </Text>
          </Link>
          <Link
            href={`/${user.github_username}/settings`}
            className="text-system-grey3 hover:text-system-grey1 transition-colors  block px-4 text-left py-2 text-sm whitespace-nowrap hover:bg-system-grey6"
          >
            <Text variant="body" className="flex items-center">
              <FiUser size={20} className="mr-4" />
              Settings
            </Text>
          </Link>
          <button
            onClick={() => refetch()}
            className="text-system-grey3 hover:text-system-grey1 transition-colors  block px-4 text-left py-2 text-sm whitespace-nowrap hover:bg-system-grey6 w-full pb-3"
          >
            <Text variant="body" className="flex items-center">
              <FiLogOut size={20} className="mr-4" />
              Logout
            </Text>
          </button>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default Account;
