import { Inter } from 'next/font/google';
import React from 'react';

const inter = Inter({ subsets: ['latin'] });

interface TextProps extends React.HTMLAttributes<HTMLParagraphElement> {
  variant: 'title' | 'body' | 'subtitle' | 'caption' | 'footnote' | 'subheader';
  children?: React.ReactNode;
  className?: string;
}

export default function Text(props: TextProps) {
  const { className, variant, ...rest } = props;

  switch (variant) {
    case 'title':
      return (
        <p className={`text-title ${inter.className} ${className}`} {...rest}>
          {props.children}
        </p>
      );
    case 'subheader':
      return (
        <p className={`text-subheader ${inter.className} ${className}`} {...rest}>
          {props.children}
        </p>
      );
    case 'caption':
      return (
        <p className={`text-caption1 ${inter.className} ${className}`} {...rest}>
          {props.children}
        </p>
      );
    case 'footnote':
      return (
        <p className={`text-footnote ${inter.className} ${className}`} {...rest}>
          {props.children}
        </p>
      );
    case 'subtitle':
      return (
        <p className={`text-subtitle ${inter.className} ${className}`} {...rest}>
          {props.children}
        </p>
      );
    default:
      // body
      return (
        <p className={`text-body ${inter.className} ${className}`} {...rest}>
          {props.children}
        </p>
      );
  }
}
