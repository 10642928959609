import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';

import { UNWEAVE_API_URL } from 'lib/constants';
import { get } from 'lib/http/fetch/base';
import type { Project } from 'lib/types';

const getProjects = async (owner: string): Promise<Project[]> => {
  const data = await get(`${UNWEAVE_API_URL}/projects/${owner}`);

  if (data.error) {
    throw new Error(data.error?.message);
  }

  if (data?.projects?.length === 0) {
    return [];
  }

  return data?.projects?.map((p: Project) => ({ ...p, createdAt: new Date(p.createdAt) }));
};

export default function useProjects(owner: string, options?: UseQueryOptions) {
  return useQuery({
    queryKey: ['projects'],
    queryFn: () => getProjects(owner),
    ...options,
  }) as UseQueryResult<Project[]>;
}
