import { useRouter } from 'next/router';
import { FC, useContext, useEffect, useState } from 'react';
import { FiCheckCircle, FiX } from 'react-icons/fi';

import useUser from 'lib/contexts/UserProvider';
import useProjects from 'lib/hooks/projects/useProjects';
import { Project } from 'lib/types';

import { Button } from 'components/ui/Button';
import { ScrollablePanel } from 'components/ui/Panel';
import { SidePanel } from 'components/ui/SidePanel';
import { Text } from 'components/ui/Typography';

import useOwner from '../../../lib/contexts/OwnerContext';
import CircularProgress from './CircularProgress';
import OnboardingStep from './OnboardingStep';

interface Props {}

const index: FC<Props> = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [itemOpen, setItemOpen] = useState(1); // starts at 1
  const [activeStep, setActiveStep] = useState(1); // starts at 1, if 0 then all steps are done
  const [lastProject, setLastProject] = useState<Project | null>(null); // starts at 1

  const { owner } = useOwner();
  const { data: projects, isSuccess: isProjectSuccess } = useProjects(owner);

  const user = useUser();
  const router = useRouter();

  // get first step that is not done
  useEffect(() => {
    setActiveStep(
      [user?.step_1, user?.step_2, user?.step_3, user?.step_4].findIndex((step) => !step) + 1
    );
  }, [user]);

  useEffect(() => {
    if (isProjectSuccess) {
      setLastProject(projects[projects.length - 1]);
    }
  }, [projects]);

  useEffect(() => {
    setItemOpen(activeStep);
  }, [activeStep]);

  const handleClick = (e: any) => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <button
        onClick={handleClick}
        className="flex items-center text-sm text-primary-white focus:outline-none transition-colors cursor-pointer"
      >
        <span className="sr-only">Open onboarding</span>
        {activeStep === 0 ? (
          <FiCheckCircle className="text-primary-green h-6 w-6" />
        ) : (
          <CircularProgress size={40} strokeWidth={2} totalSteps={3} currentStep={activeStep - 1} />
        )}
      </button>
      <SidePanel
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <SidePanel.Header
          title="Onboarding"
          icon={FiX}
          onNavigation={() => {
            setIsOpen(false);
          }}
        />
        <SidePanel.Body>
          <SidePanel.Description>
            Change the name for your project in the field provided. You can also choose to delete
            your project by selecting the delete button. Deleting a project on Unweave is not
            reversible.
          </SidePanel.Description>
          <div className="flex-grow flex flex-col justify-between overflow-hidden">
            <ScrollablePanel className="flex-grow">
              <OnboardingStep
                isOpen={itemOpen === 1}
                onOpen={() => setItemOpen(1)}
                isActive={activeStep === 1}
                isDone={user?.step_1}
                number={1}
                title="Connect Provider"
                content={
                  <Text variant="body" className="text-system-grey4">
                    You can connect your cloud accounts to Unweave. Once connected, you can choose
                    to run any new sessions on your cloud provider.
                  </Text>
                }
                action={
                  <Button
                    type="primary"
                    variant="action"
                    className="w-full"
                    onClick={() => {
                      router.push(`/${user.github_username}/settings`);
                      setIsOpen(false);
                    }}
                  >
                    Open Settings
                  </Button>
                }
              />
              <OnboardingStep
                isOpen={itemOpen === 2}
                onOpen={() => setItemOpen(2)}
                /* @ts-ignore */
                isDone={user?.step_2}
                isActive={activeStep === 2}
                number={2}
                title="Create project"
                content={
                  <Text variant="body" className="text-system-grey4">
                    Resources in Unweave are scoped at a project level. You can think of a project
                    at the same level as a GitHub repository.
                  </Text>
                }
                action={
                  <Button
                    onClick={() => {
                      router.push(`/${`/${owner}`}`);
                      setIsOpen(false);
                    }}
                    type="primary"
                    variant="action"
                    className="w-full"
                  >
                    Open dashboard
                  </Button>
                }
              />
              <OnboardingStep
                isOpen={itemOpen === 3}
                onOpen={() => setItemOpen(3)}
                isActive={activeStep === 3}
                /* @ts-ignore */
                isDone={user?.step_3}
                isLast={true}
                number={3}
                title="Create Session"
                content={
                  <Text variant="body" className="text-system-grey4">
                    Unweave sessions are GPU virtual machines that you can connect to through SSH.
                    You can use them to run your training jobs.
                  </Text>
                }
                action={
                  <>
                    <Button
                      disabled={!lastProject}
                      type="primary"
                      variant="action"
                      className="w-full"
                      onClick={() => {
                        router.push(`/${user.github_username}/${lastProject?.name}`);
                        setIsOpen(false);
                      }}
                    >
                      Open Sessions
                    </Button>
                    {!lastProject && (
                      <Text variant="caption" className="text-primary-red mt-2">
                        You need to create a project first.
                      </Text>
                    )}
                  </>
                }
              />
            </ScrollablePanel>
            <div className="space-y-3 mt-3">
              <Button
                onClick={() => setIsOpen(false)}
                type="primary"
                variant="large"
                className="w-full"
              >
                Close
              </Button>
            </div>
          </div>
        </SidePanel.Body>
      </SidePanel>
    </div>
  );
};

export default index;
