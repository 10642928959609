import { Menu, Transition } from '@headlessui/react';
import Image from 'next/image';
import React, { Fragment } from 'react';
import { FiBookOpen, FiHelpCircle, FiMail } from 'react-icons/fi';

import { Button } from 'components/ui/Button';
import { Heading, Text } from 'components/ui/Typography';

import discordBackground from 'public/discord-bg-small.webp';
import discordLogo from 'public/discord.svg';

export default function Help() {
  return (
    <div className="flex items-center">
      <Menu as="div" className="relative">
        <div>
          <Menu.Button className="flex text-primary-white hover:text-system-grey4 transition-colors">
            <span className="sr-only">Open user menu</span>
            <FiHelpCircle className="h-6 w-6 rounded-full" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="cursor-pointer p-4 origin-top-right absolute right-0 mt-5 w-80 rounded-md shadow-lg text-primary-white bg-system-grey6 ring-0 ring-black ring-opacity-5 focus:outline-none">
            <div className={'discord p-2 flex flex-col'}>
              <Heading variant={'title'} className={'text-primary-white'}>
                Need help?
              </Heading>
              <Text variant={'subtitle'} className={'text-system-grey4 py-1'}>
                The quickest way to reach us is through Discord. If you're old school and prefer
                email, that's fine too.
              </Text>
              <div className="flex space-x-3">
                <Button
                  variant={'action'}
                  type={'primary'}
                  icon={FiMail}
                  href={'mailto:info@unweave.io'}
                >
                  Contact support
                </Button>
                <Button
                  variant={'action'}
                  type={'secondary'}
                  icon={FiBookOpen}
                  onClick={() => window.open('https://docs.unweave.io/')}
                >
                  Docs
                </Button>
              </div>
            </div>
            <div className={'discord p-2 flex flex-col'}>
              <Heading variant={'title'} className={'text-primary-white'}>
                Reach out to the community
              </Heading>
              <Text variant={'subtitle'} className={'text-system-grey4 py-1'}>
                Join our Discord server to chat with other Unweave users or reach out to us on the
                help channel.
              </Text>
              <div className="relative overflow-hidden rounded px-5 py-4 h-36 mt-2">
                <Image
                  className="opacity-60"
                  src={discordBackground}
                  fill
                  style={{ objectFit: 'cover' }}
                  alt="discord illustration"
                  sizes={'100%'}
                />
                <Button
                  type="secondary"
                  className={'opacity-100 absolute'}
                  onClick={() => window.open('https://discord.gg/ydyVHbFjPt', '_blank')}
                >
                  <span className={'flex space-x-2'}>
                    <Image src={discordLogo} alt="discord logo" height={16} width={16} />
                    <span>Join discord server</span>
                  </span>
                </Button>
              </div>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
