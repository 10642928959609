import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import React, { createContext, useContext, useEffect, useState } from 'react';

import Error_404 from '../../components/interfaces/404';
import LoadingSpinner from '../../components/ui/LoadingSpinner';
import useOrganizations from '../hooks/account/useOrganizations';
import { Organization, User } from '../types';
import useUser from './UserProvider';
import getUser from './getUser';

type OwnerContextType = {
  owner: string;
  setOwner: (owner: string) => void;
};

const OwnerContext = createContext<OwnerContextType>(null);

const OwnerProvider = ({ children }) => {
  const router = useRouter();

  const user = useUser();
  const [owner, setOwner] = useState<string>(user.github_username);

  const setOwnerChange = (owner: string) => {
    // TODO:
    // setOwner(owner);
    // router.replace('/[owner]', `/${owner}`);
  };

  return (
    <>
      <OwnerContext.Provider value={{ owner, setOwner: setOwnerChange }}>
        {children}
      </OwnerContext.Provider>
    </>
  );
};

const useOwner = () => useContext(OwnerContext);

export default useOwner;
export { OwnerProvider };
