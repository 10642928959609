import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';
import posthog from 'posthog-js';

import { supabase } from 'lib/supabase';

const signOut = async () => {
  const { error } = await supabase.auth.signOut();
  if (error) {
    throw new Error(error.message);
  }
  return true;
};

export default function useSignOut(options?: UseQueryOptions) {
  return useQuery({
    queryKey: ['signOut'],
    queryFn: () => signOut(),
    onSuccess: () => {
      posthog.reset();
    },
    ...options,
  }) as UseQueryResult<Boolean>;
}
