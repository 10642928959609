import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { FiCheck, FiChevronDown } from 'react-icons/fi';

import { Heading, Text } from 'components/ui/Typography';

interface Props {
  number: number;
  isOpen: boolean;
  isDone: boolean;
  isActive: boolean;
  isLast?: boolean;
  title: string;
  content: ReactNode;
  onOpen?: () => void;
  action?: ReactNode;
}

const OnboardingStep: FC<Props> = ({
  isOpen,
  isDone,
  isLast = false,
  isActive,
  number,
  title,
  content,
  action,
  onOpen,
}: Props) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState<number | null>(null);

  useEffect(() => {
    if (isOpen && contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    } else {
      setContentHeight(null);
    }
  }, [isOpen]);

  return (
    <div className="flex space-x-4">
      <div className="flex flex-col items-center">
        <button
          onClick={onOpen}
          className={`w-10 h-10 rounded-full flex justify-center items-center ${
            isDone
              ? 'bg-system-grey3 text-primary-black'
              : isActive
              ? 'bg-primary-blue text-primary-white'
              : 'border-2 border-system-grey3 text-system-grey3'
          }`}
        >
          {isDone ? (
            <FiCheck className="w-4 h-4" />
          ) : (
            <Text variant="subtitle">{number.toString()}</Text>
          )}
        </button>
        {!isLast && <div className="w-0.5 bg-system-grey3 flex-grow"></div>}
      </div>
      <div className="mb-4">
        <button className="text-left h-10 flex items-center" onClick={onOpen}>
          <Heading
            variant="title"
            className={`flex items-center ${
              isDone || isActive ? 'text-primary-white' : 'text-system-grey3'
            }`}
          >
            {
              <FiChevronDown
                className={`inline-block mr-2 transition-transform ${isOpen ? '' : '-rotate-90'}`}
              />
            }
            {title}
          </Heading>
        </button>
        <div
          ref={contentRef}
          className={`transition-all overflow-hidden ${isOpen ? 'space-y-3' : 'space-y-0'}`}
          style={{ height: isOpen ? contentHeight ?? 'auto' : '0px' }}
        >
          <div>{content}</div>
          {action && <div>{action}</div>}
        </div>
      </div>
    </div>
  );
};

export default OnboardingStep;
