import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { PropsWithChildren } from 'react';

import useUser from 'lib/contexts/UserProvider';

import OnboardingPanel from 'components/interfaces/OnboardingPanel';
import Credit from 'components/ui/Credit';
import Account from 'components/ui/Menus/Account';
import HelpMenu from 'components/ui/Menus/Help';

type Props = {
  color?: string;
  size?: 'regular' | 'small';
  className?: string;
  breadcrumbs?: React.ReactElement;
};

function NavigationBar(props: PropsWithChildren<Props>) {
  const { breadcrumbs } = props;
  const router = useRouter();

  const { owner } = router.query as { owner: string; repo: string; branch: string; path: string[] };

  const user = useUser();

  return (
    <div
      className={
        'w-full fixed top-0 bg-primary-black left-0 right-0 px-3 border-b border-system-grey6 z-100'
      }
    >
      <div
        className={`min-h-16 py-2 flex items-center justify-between text-primary-white ${
          props.className || ''
        }`}
      >
        <div className="space-x-6 flex items-center">
          <Link href={owner ? `/${owner}` : '/'}>
            <Image className="h-10" src="/logo.svg" width={40} height={40} alt="Unweave Logo" />
          </Link>
          {breadcrumbs || null}
        </div>

        <div className="space-x-6 flex items-center">
          <Link href={`/${user.github_username}/settings`}>
            <Credit credit={user?.credit} />
          </Link>
          <HelpMenu />
          <OnboardingPanel />
          <Account />
        </div>
      </div>
      {props.children}
    </div>
  );
}

export default NavigationBar;
