import { Transition } from '@headlessui/react';
import React, { useEffect } from 'react';
import { FiArrowLeft, FiX } from 'react-icons/fi';

import NavigationHeader from './NavigationHeader';
import { Heading } from './Typography';

type HeaderProps = {
  title: string;
  onNavigation: () => void;
  icon: typeof FiX | typeof FiArrowLeft;
  ref?: React.RefObject<HTMLDivElement>;
};

function Header({ title, icon, onNavigation }: HeaderProps) {
  return <NavigationHeader onNavigation={onNavigation} icon={icon} title={title} />;
}

function Body({ children }: React.PropsWithChildren<{}>) {
  return <div className="flex flex-col flex-grow overflow-hidden">{children}</div>;
}

function Description({ children }: React.PropsWithChildren<{}>) {
  return (
    <Heading variant={'h5'} className="text-system-grey5 py-2 mb-6">
      {children}
    </Heading>
  );
}

type SidePanelProps = {
  isOpen: boolean;
  children?: React.ReactNode;
  onClose: () => void;
  className?: string;
  top?: number;
};

function SidePanel({ isOpen, children, onClose, className, top = 16 }: SidePanelProps) {
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, [onClose]);

  return (
    <div
      className={`absolute z-80 left-0 right-0 bottom-0 cursor-pointer flex justify-end overflow-hidden transition-opacity bg-primary-black h-[calc(100vh-${top})] bg-opacity-64 ${
        isOpen ? '' : 'pointer-events-none opacity-0'
      } top-${top}`}
      onClick={onClose}
    >
      <div
        className={`cursor-pointer flex justify-end text-white overflow-hidden transition-opacity`}
      >
        <Transition
          appear={true}
          show={isOpen}
          enter="transition-transform ease-out duration-100 transform"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition-transform ease-in duration-75 transform"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <div
            className={`side-panel w-180 flex h-full max-w-full bg-system-grey7 cursor-default overflow-hidden ${
              className || ''
            }`}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="w-180">
              <div className="p-6 pt-10 pr-10 pb-8 h-full space-y-6 flex flex-col py-6">
                {children}
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
}

SidePanel.Header = Header;
SidePanel.Body = Body;
SidePanel.Description = Description;

export { SidePanel };
