import { RequestInit } from 'next/dist/server/web/spec-extension/request';

import getAuthToken from 'lib/http/auth';
import { UnweaveErrorResponse, UnweaveResponse } from 'lib/http/types';

async function handleResponse(response: Response) {
  const text = await response.text();
  try {
    return JSON.parse(text);
  } catch (error) {
    return text;
  }
}

export function handleError<T>(e: Error): UnweaveResponse<T> {
  const message = e?.message
    ? `Oh no! Something went wrong: ${e.message}`
    : 'Oh no! Something went wrong.';
  const error = { code: 500, message };
  return { error } as unknown as UnweaveResponse<T>;
}

export async function post<T = any>(
  url: string,
  body: any,
  options?: RequestInit
): Promise<UnweaveResponse<T>> {
  try {
    const token = await getAuthToken();
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
      ...options,
    });

    if (!response.ok) {
      const error = (await response.json()) as UnweaveErrorResponse;
      return { error } as unknown as UnweaveResponse<T>;
    }
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
}

export async function put<T = any>(
  url: string,
  body: any,
  options?: RequestInit
): Promise<UnweaveResponse<T>> {
  try {
    const token = await getAuthToken();
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
      ...options,
    });

    if (!response.ok) {
      const error = (await response.json()) as UnweaveErrorResponse;
      return { error } as unknown as UnweaveResponse<T>;
    }
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
}

export async function get<T = any>(
  url: string,
  options?: RequestInit
): Promise<UnweaveResponse<T>> {
  try {
    const token = await getAuthToken();
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      ...options,
    });

    if (!response.ok) {
      const error = (await response.json()) as UnweaveErrorResponse;
      return { error } as unknown as UnweaveResponse<T>;
    }
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
}

export async function del<T = any>(
  url: string,
  options?: RequestInit
): Promise<UnweaveResponse<T>> {
  try {
    const token = await getAuthToken();
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      ...options,
    });

    if (!response.ok) {
      const error = (await response.json()) as UnweaveErrorResponse;
      return { error } as unknown as UnweaveResponse<T>;
    }
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
}
