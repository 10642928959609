import { createClient } from '@supabase/supabase-js';

import { SUPABASE_ANON_KEY, SUPABASE_URL } from 'lib/constants';
import { Database } from 'lib/database.types';

export const supabase = createClient<Database>(SUPABASE_URL, SUPABASE_ANON_KEY);
export const supabasePlatform = createClient<Database>(SUPABASE_URL, SUPABASE_ANON_KEY, {
  db: {
    // @ts-ignore
    schema: 'platform',
  },
});
