import React from 'react';

import { Text } from 'components/ui/Typography';

export default function Credit({
  credit,
  className,
  variant = 'body',
}: {
  credit: number;
  className?: string;
  variant?: 'body' | 'caption' | 'subtitle' | 'footnote' | 'subheader';
}) {
  const creditColor =
    credit < 5 ? 'text-primary-yellow' : credit <= 0 ? 'text-primary-red' : 'text-primary-green';

  return (
    <Text variant={variant} className={`${creditColor} ${className}`}>
      $ {credit.toFixed(2)}
    </Text>
  );
}
