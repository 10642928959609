import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';

import { supabase } from 'lib/supabase';
import { User } from 'lib/types';

import { UNWEAVE_APP_URL } from '../../constants';

const signInWithOAuth = async (path?: string) => {
  const { data, error } = await supabase.auth.signInWithOAuth({
    provider: 'github',
    options: {
      redirectTo: `${UNWEAVE_APP_URL}/auth/github/callback?referrerPath=${path}`,
    },
  });

  if (error) {
    throw new Error(error.message);
  }

  if (!data) {
    throw new Error('User not found');
  }

  return data;
};

export default function useSignInWithGithub(path?: string, options?: UseQueryOptions) {
  return useQuery({
    queryKey: ['signInWithGithub'],
    queryFn: () => signInWithOAuth(path),
    ...options,
  }) as UseQueryResult<User>;
}
