export const UNWEAVE_APP_URL =
  process?.env?.NEXT_PUBLIC_SITE_URL ??
  process?.env?.NEXT_PUBLIC_VERCEL_URL ??
  'http://localhost:3000/';
export const UNWEAVE_API_URL = process.env.NEXT_PUBLIC_UNWEAVE_API_ENDPOINT as string;
export const SUPABASE_URL = process.env.NEXT_PUBLIC_SUPABASE_URL as string;
export const SUPABASE_ANON_KEY = process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY as string;
export const GITHUB_REDIRECT_URI = `${SUPABASE_URL}/auth/v1/callback` as string;
export const GITHUB_CLIENT_ID = process.env.NEXT_PUBLIC_GITHUB_CLIENT_ID as string;
export const GITHUB_APP_NAME = process.env.NEXT_PUBLIC_GITHUB_APP_NAME as string;
export const POSTHOG_API_KEY = process.env.NEXT_PUBLIC_POSTHOG_API_KEY as string;
export const NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY = process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY;
