import { supabase } from 'lib/supabase';
import { User } from 'lib/types';

const getUser = async (): Promise<User> => {
  const {
    data: { user },
  } = await supabase.auth.getUser();

  const { data, error } = await supabase.from('users').select('*').single();

  if (error) {
    throw new Error(error.message);
  }

  if (!user || !data) {
    return null;
  }

  return {
    ...data,
    avatar_url: user.user_metadata.avatar_url,
  };
};

export default getUser;
