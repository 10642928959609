import { IconType } from 'react-icons';

type Props = {
  icon: IconType;
  isActive?: boolean;
  variant: 'large' | 'small';
  className?: string;
  iconSize?: number;
  onClick?: () => void;
};

const getVariant = (type: string) => {
  switch (type) {
    case 'large': {
      return 'w-10 h-10';
    }
    case 'small': {
      return 'w-6 h-6';
    }
    default: {
      // large
      return 'w-12 h-12';
    }
  }
};

export default function IconButton(props: Props) {
  const { icon, isActive, variant, className, iconSize, onClick } = props;
  const Icon = icon;

  function click() {
    if (onClick) {
      onClick();
    }
  }

  return (
    <button
      className={`rounded-lg flex justify-center transition-colors items-center ${
        isActive ? 'text-primary-white' : 'text-system-grey5'
      } hover:text-system-grey4 ${getVariant(variant)} ${className || ''} `}
      onClick={() => click()}
    >
      <Icon size={iconSize || 20} />
    </button>
  );
}
