import React from 'react';

import { Text } from 'components/ui/Typography';

type CircularProgressType = {
  currentStep?: number;
  totalSteps?: number;
  strokeWidth?: number;
  size?: number;
};

const CircularProgress = ({
  currentStep,
  totalSteps,
  size = 40,
  strokeWidth = 2,
}: CircularProgressType) => {
  const fillColor = 'transparent';

  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const ratio = currentStep && totalSteps ? currentStep / totalSteps : 0.5;
  const offset = circumference - ratio * circumference;

  return (
    <div className="relative">
      <svg width={size} height={size}>
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={strokeWidth}
          fill={fillColor}
          className="transition-all duration-500 ease-in-out stroke-system-grey6"
        />
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={strokeWidth}
          fill="none"
          className="transition-all duration-500 ease-in-out stroke-primary-blue"
          strokeLinecap="square"
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={offset}
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
        />
      </svg>
      <Text
        variant="subtitle"
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-primary-white"
      >
        {currentStep ?? ''}
      </Text>
    </div>
  );
};

export default CircularProgress;
