import React from 'react';

type Props = {
  color?: string;
  size?: 'regular' | 'small';
};

export default function LoadingSpinner(props: Props) {
  const { color, size } = props;
  return (
    <div className="flex h-full items-center justify-center overflow-hidden">
      <span
        style={{ borderTopColor: 'transparent' }}
        className={`border-solid rounded-full animate-spin ${color || 'border-primary-blue'} ${
          size === 'small' ? 'w-4 h-4 border-2' : 'w-8 h-8 border-4'
        }`}
      />
    </div>
  );
}
