// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from '@sentry/react';
import { SessionContextProvider } from '@supabase/auth-helpers-react';
import { QueryClientProvider } from '@tanstack/react-query';
import { AppProps } from 'next/app';
import Head from 'next/head';
import posthog from 'posthog-js';
import React from 'react';
import 'styles/globals.css';

import { POSTHOG_API_KEY } from 'lib/constants';
import { OwnerProvider } from 'lib/contexts/OwnerContext';
import { UserProvider } from 'lib/contexts/UserProvider';
import queryClient from 'lib/http/query';
import { NextPageWithLayout } from 'lib/next';
import { supabase } from 'lib/supabase';

import PostHogWrapper from 'components/interfaces/App/PosthogWrapper';
import Error from 'components/ui/States/Error';

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

if (typeof window !== 'undefined') {
  posthog.init(POSTHOG_API_KEY, {
    api_host: 'https://eu.posthog.com',
    autocapture: false,
    // loaded: (posthog) => {
    //   if (process.env.NODE_ENV === 'development') {
    //     posthog.opt_out_capturing();
    //   }
    // },
  });
}

function App({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <>
      <Head>
        <link rel="icon" type="image/x-icon" href="/logo.svg"></link>
        <title>Unweave</title>
      </Head>
      <Sentry.ErrorBoundary fallback={<Error message="Oops, something went wrong" />}>
        <QueryClientProvider client={queryClient}>
          <SessionContextProvider
            supabaseClient={supabase}
            initialSession={pageProps?.initialSession}
          >
            <UserProvider>
              <OwnerProvider>
                <PostHogWrapper>{getLayout(<Component {...pageProps} />)}</PostHogWrapper>
              </OwnerProvider>
            </UserProvider>
          </SessionContextProvider>
        </QueryClientProvider>
      </Sentry.ErrorBoundary>
    </>
  );
}

export default App;
