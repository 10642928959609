import Image from 'next/image';
import { FC, ReactNode } from 'react';

import { Heading, Text } from 'components/ui/Typography';

import logo from 'public/logo.svg';

import Layout from './Layout';

interface Props {
  showLogo?: boolean;
  title: string;
  description?: string | ReactNode;
  children?: ReactNode;
}

const CenteredInfoLayout: FC<Props> = ({ showLogo, title, description, children }: Props) => (
  <Layout className="flex justify-center items-center">
    <div className="w-102 bg-system-grey7 flex space-y-8 flex-col items-center pt-12 pb-6 rounded-lg px-6">
      {showLogo && <Image width={80} height={80} src={logo} alt="logo" />}
      <div className="text-center flex flex-col items-center space-y-2">
        <Heading variant="h2" className="text-primary-white">
          {title}
        </Heading>
        {typeof description === 'string' ? (
          <Text variant="body" className="text-system-grey5">
            {description}
          </Text>
        ) : (
          description
        )}
      </div>
      {children}
    </div>
  </Layout>
);

export default CenteredInfoLayout;
