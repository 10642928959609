import { useSession } from '@supabase/auth-helpers-react';
import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';
import { createContext, useContext } from 'react';

import { User } from 'lib/types';

import Auth from 'components/interfaces/Auth';
import LoadingSpinner from 'components/ui/LoadingSpinner';

import queryClient from '../http/query';
import getUser from './getUser';

const UserContext = createContext<User>(null);

const UserProvider = ({ children }) => {
  const session = useSession();
  const { data: user, isInitialLoading, isError } = userQuery({ enabled: !!session?.user });

  return (
    <>
      {isInitialLoading ? (
        <LoadingSpinner />
      ) : isError || !session?.user ? (
        <Auth />
      ) : (
        <UserContext.Provider value={user}>{children}</UserContext.Provider>
      )}
    </>
  );
};

function userQuery(options?: UseQueryOptions) {
  return useQuery({
    queryKey: ['user'],
    queryFn: () => getUser(),
    ...options,
  }) as UseQueryResult<User>;
}

function useUser() {
  return useContext(UserContext);
}

function refetchUser() {
  queryClient.refetchQueries({ queryKey: ['user'], type: 'active' });
}
export default useUser;
export { UserProvider, refetchUser };
