import { FC } from 'react';
import { IconType } from 'react-icons';

import IconButton from './Button/IconButton';
import { Heading } from './Typography';

interface Props {
  title: string;
  icon: IconType;
  className?: string;
  onNavigation: () => void;
}

const NavigationHeader: FC<Props> = ({ title, icon, className, onNavigation }: Props) => (
  <div className={`flex items-center space-x-6 ${className || ''}`}>
    <IconButton
      onClick={onNavigation}
      icon={icon}
      variant="large"
      isActive={false}
      className="bg-system-grey6 hover:bg-system-grey5 text-primary-white"
    />

    <Heading variant="h2" className="text-primary-white ">
      {title}
    </Heading>
  </div>
);

export default NavigationHeader;
