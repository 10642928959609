import React from 'react';
import { FiChevronRight } from 'react-icons/fi';

import BaseButton, {
  ButtonAsButton,
  ButtonAsLink,
  getVariant,
} from 'components/ui/Button/Button.utils';

type ButtonProps = ButtonAsButton | ButtonAsLink;

export const NavigationButton = ({ variant = 'action', ...props }: ButtonProps) => {
  const { className, icon, autofocus, ...rest } = props;
  const autoFocus = !!autofocus;

  const iconBase = `${variant === 'large' ? 'h-5 w-5' : 'h-3.33 w-3.33 mt-0.5'} mr-2.33`;
  const base = `inline-flex items-center justify-between rounded-default text-primary-white font-inter bg-primary-black hover:bg-system-grey7 hover:text-primary-white active:bg-system-grey6 active:text-primary-white disabled:bg-primary-black disabled:text-system-grey5`;
  const classes = `${base} ${className} ${getVariant(variant)}}`;
  const Icon = icon;

  return (
    <BaseButton autofocus={autoFocus} className={classes} {...rest}>
      <div className="flex justify-center items-center">
        {Icon && <Icon className={iconBase} />}
        {props.children}
      </div>
      <FiChevronRight className="text-system-grey5 ml-2.33" />
    </BaseButton>
  );
};
